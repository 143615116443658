<template>
    <div id="index" ref="appRef">
      <div class="bg">
        <!-- <dv-loading>Loading...</dv-loading> -->
        <img class="feack" src="../assets/img/fanhui.png" alt="" @click="back" />
        
        <div class="icon_time">
            <img src="../assets/img/ic_tb_time.png" alt="">
            {{ dateYear }} {{dateWeek}} {{ dateDay }}
        </div>

        <div class="title">九赋生活指挥中心-众创中心</div>
        <img class="title_bj" src="../assets/img/title_bj.png" alt="">


        <div class="massLeft">

            <div class="newUser" style="background:#051B4A;">
                <dv-border-box-8 style=" width:609px; height:261px;">
                    <div class="dayGmv_title">
                        <img src="../assets/img/dayGmv_left.png" alt="" />
                        总渠道新增用户
                        <img src="../assets/img/dayGmv_right.png" alt="" />
                    </div>

                    <div class="price">
                        <div class="num">
                            <CountTo :startVal='startVal' :endVal='endVal1' :duration='duration' />
                        </div>
                        <div class="day_num">
                            较昨日 <span :style="{color:endVal4*1 >= 0 ? '#FF0000':'#14FF39'}">{{endVal4}}%</span>
                            <img v-if="endVal4*1 >= 0 " src="../assets/img/dayGmv_top.png" alt="">
                            <img v-else src="../assets/img/dayGmv_botton.png" alt="">
                        </div>
                    </div>

                    <div class="newPrice">
                        <div class="newPrice_left">
                            <div class="newPrice_left_title">
                                <p><span></span>本月新增</p>
                            </div>
                            <div class="unm">
                                <CountTo :startVal='startVal' :endVal='endVal2' :duration='duration' />
                            </div>
                        </div>
                        <div class="newPrice_left" style="border-right: none;">
                            <div class="newPrice_left_title">
                                <p style="color: #3467FF;"><span style="background-color: #3467FF;"></span>本年新增</p>
                            </div>
                            <div class="unm" style="color: #3467FF;">
                                <CountTo :startVal='startVal' :endVal='endVal3' :duration='duration' />
                            </div>
                        </div>
                    </div>

                </dv-border-box-8>
            </div>
            <!-- 总渠道新增用户 -->

            <div class="newUser" style="background:#051B4A; margin-left:30px">
                <dv-border-box-8 :reverse="true" style=" width:609px; height:261px;">
                    <div class="dayGmv_title">
                        <img src="../assets/img/dayGmv_left.png" alt="" />
                        总渠道GMV
                        <img src="../assets/img/dayGmv_right.png" alt="" />
                    </div>

                    <div class="price">
                        <div class="num">
                            <CountTo :startVal='startVal' :endVal='gmvendVal1' :duration='duration' />
                        </div>
                        <div class="day_num">
                            较昨日 <span :style="{color:gmvendVal4*1 >= 0 ? '#FF0000':'#14FF39'}">{{gmvendVal4}}%</span>
                            <img v-if="gmvendVal4*1 >= 0 " src="../assets/img/dayGmv_top.png" alt="">
                            <img v-else src="../assets/img/dayGmv_botton.png" alt="">
                        </div>
                    </div>

                    <div class="newPrice">
                        <div class="newPrice_left">
                            <div class="newPrice_left_title">
                                <p><span></span>本月新增</p>
                            </div>
                            <div class="unm">
                                <CountTo :startVal='startVal' :endVal='gmvendVal2' :duration='duration' />
                            </div>
                        </div>
                        <div class="newPrice_left" style="border-right: none;">
                            <div class="newPrice_left_title">
                                <p style="color: #3467FF;"><span style="background-color: #3467FF;"></span>本年新增</p>
                            </div>
                            <div class="unm" style="color: #3467FF;">
                                <CountTo :startVal='startVal' :endVal='gmvendVal3' :duration='duration' />
                            </div>
                        </div>
                    </div>

                </dv-border-box-8>
            </div>
            <!-- 总渠道GMV -->

            <div class="sphere">
                <div class="sphere_title">
                    <div class="titletext">
                        渠道销售额统计
                    </div>
                    <div class="sphere_tabqiehuan">
                        <span @click="sphereClick1(1)" :class="sphere1 == 1 ? 'on':''" style="margin-right: 30px;">本周</span>
                        <span @click="sphereClick1(2)" :class="sphere1 == 2 ? 'on':''">本月</span>
                    </div>
                </div>
                <div class="sphere_con" id="main1"></div>
            </div>

            <!-- 私域流量渠道销售额统计 -->

            <div class="sphere">
                <div class="sphere_title">
                    <div class="titletext">
                        私域流量渠道销售额统计
                    </div>
                    <div class="sphere_tabqiehuan">
                        <span @click="sphereClick2(1)" :class="sphere2 == 1 ? 'on':''" style="margin-right: 30px;">本周</span>
                        <span @click="sphereClick2(2)" :class="sphere2 == 2 ? 'on':''">本月</span>
                    </div>
                </div>
                <div class="sphere_con" id="main2"></div>
            </div>

            <!-- 渠道销售额统计 -->

            <div class="sphere">
                <div class="sphere_title">
                    <div class="titletext">
                        私域流量渠道用户新增统计
                    </div>
                    <div class="sphere_tabqiehuan">
                        <span @click="sphereClick3(1)" :class="sphere3 == 1 ? 'on':''" style="margin-right: 30px;">本周</span>
                        <span @click="sphereClick3(2)" :class="sphere3 == 2 ? 'on':''">本月</span>
                    </div>
                </div>
                <div class="sphere_con" id="main3"></div>
            </div>

            <!-- 私域流量渠道用户新增统计 -->

            <div class="sphere">
                <div class="sphere_title">
                    <div class="titletext" style="color: #fff;">
                        渠道数据统计
                    </div>
                </div>
                <div class="sphere_conlist">
                    <div class="title_text">
                        <span style="width: 60px;">渠道</span>
                        <span style="width: 95px;">应用</span>
                        <span style="width: 100px;">总用户</span>
                        <span style="width: 109px;">在售商品</span>
                        <span style="width: 109px;">销售总额</span>
                        <span style="width: 109px;">铺货商品</span>
                    </div>


                    <div class="massRight_con">
                        <div class="conlist" v-for="(item,index) in channelList" :key="index">
                            <div class="channel">
                                <div class="channel_left">{{ item.channel }}</div>
                                <div class="channel_right">
                                    <div v-for="(itemc,indexc) in item.applyList" :key="indexc">
                                        <span style="width: 70px; text-align: right;">{{ itemc.apply }}</span>
                                        <span style="width: 150px;">{{ itemc.user_num }}</span>
                                        <span style="width: 80px;">{{ itemc.sell_goods_number }}</span>
                                        <span style="width: 120px;">{{ itemc.order_num }}</span>
                                        <span style="width: 100px;">{{ itemc.distribution_goods_number }}</span>
                                    </div>
                                </div>
                            </div>
                            
                        </div>
                    </div>

                </div>
            </div>

            <!-- 渠道数据统计 -->

 
        </div>
        <!-- 左 -->

        <div class="massRight">
            <div class="massRight_title">今日订单统计</div>
            <div class="title_text">
                <span style="width: 130px;">用户</span>
                <span style="width: 90px;">付款时间</span>
                <span style="width: 80px;">付款金额</span>
                <span style="width: 100px;">渠道</span>
                <span style="width: 100px;">应用</span>
            </div>
            <div class="massRight_con">
                <div class="conlist" v-for="(item,index) in orderList" :key="index">
                    <span style="width: 130px;">{{ item.phone }}</span>
                    <span style="width: 90px;">{{ item.pay_time }}</span>
                    <span style="width: 80px;">{{ item.money }}</span>
                    <span style="width: 100px;">{{ item.channel }}</span>
                    <span style="width: 100px;">{{ item.apply }}</span>
                </div>
            </div>
        </div>

        <!-- 右 -->
      </div>
    </div>
  </template>
  
<script>
import { Channel,apply,user,getUserSum,getChannelList,getOrderLits } from '../config/httpApi';
import echarts from 'echarts';
import CountTo from 'vue-count-to';
import drawMixin from "../utils/drawMixin";
import { formatTime } from '../utils/index.js';
  export default {
    components: {
        CountTo
    },
    mixins: [drawMixin],
    data() {
      return {
        timing: null,
        loading: true,
        dateDay: null,
        dateYear: null,
        dateWeek: null,
        weekday: ['周日', '周一', '周二', '周三', '周四', '周五', '周六'],

        startVal: 0,//初始化
        duration: 1000,//总渠道新增用户

        endVal1: 0,//总渠道新增用户
        endVal2: 0,//总渠道新增用户
        endVal3: 0,//总渠道新增用户
        endVal4:0,
        gmvendVal1:0, //总渠道GMV
        gmvendVal2:0, //总渠道GMV
        gmvendVal3:0, //总渠道GMV
        gmvendVal4:0,

        sphere1:'1', //私域流量渠道销售额统计
        sphere2:'1', //渠道销售额统计
        sphere3:'1', //私域流量渠道用户新增统


        channelList:[], //渠道数据统计源
        channeCount:0,  //渠道数据统计总数

        orderList:[], //今日订单统计源
        orderCount:0,   //今日订单统计总数
        
      }
    },
    mounted() {
        this.timeFn();
        this.cancelLoading();

        this.getUserSum();  //总渠道新增用户--总渠道GMV 
        this.getChannelLists();  //渠道数据统计
        this.getOrderLits();    //今日订单统计
        this.Channel(); //私域流量渠道销售额统计
        this.apply();   //渠道销售额统计
        this.initChartuser();   //私域流量渠道用户新增统
        let that = this;

        setInterval(function() {
        //发送AJAX请求的代码
            that.endVal1 = 0;//总渠道新增用户
            that.endVal2 = 0;//总渠道新增用户
            that.endVal3 = 0;//总渠道新增用户
            that.endVal4 = 0;

            that.gmvendVal1 = 0; //总渠道GMV
            that.gmvendVal2 = 0; //总渠道GMV
            that.gmvendVal3 = 0; //总渠道GMV
            that.gmvendVal4 = 0;
            that.getUserSum();  //总渠道新增用户--总渠道GMV 
        }, 60000); //600000毫秒 = 10分钟
        setInterval(function() {
        //发送AJAX请求的代码
            that.channelList = [];
            that.orderList = [];
            that.getChannelLists();  //渠道数据统计
            that.getOrderLits();    //今日订单统计
            that.Channel(); //私域流量渠道销售额统计
            that.apply();   //渠道销售额统计
            that.initChartuser();   //私域流量渠道用户新增统
        }, 600000); //600000毫秒 = 10分钟
    },
    beforeDestroy() {
      clearInterval(this.timing)
    },
    methods: {
        back(){
            this.$router.back();
        },
        getUserSum(){  //总渠道新增用户--总渠道GMV 
            getUserSum({}).then(res => {
                //后端返回的数据
                // console.log(res)
                // this.userSum = res;
                this.endVal1 = res.A.a;//总渠道新增用户
                this.endVal2 = res.A.d;//总渠道新增用户
                this.endVal3 = res.A.e;//总渠道新增用户
                this.endVal4 = res.A.b;

                this.gmvendVal1 = res.B.a; //总渠道GMV
                this.gmvendVal2 = res.B.d; //总渠道GMV
                this.gmvendVal3 = res.B.e; //总渠道GMV
                this.gmvendVal4 = res.B.b;
            })
        },
        getChannelLists() {   //渠道数据统计
            let option = {
                page:1,
                perPage:100
            };
            getChannelList(option).then(res => {
                //后端返回的数据
                // console.log(res)
                this.channeCount = res.count;
                this.channelList = res.list;
            })
        },
        getOrderLits(){    //今日订单统计
            let option = {
                page:1,
                perPage:200
            };
            getOrderLits(option).then(res => {
                //后端返回的数据
                // console.log(res)
                this.orderCount = res.count;
                this.orderList = res.list;
            })
        },
        Channel(){  //私域流量渠道销售额统计
            let option = {
                type:this.sphere1
            };
            Channel(option).then(res => {
                //后端返回的数据
                // console.log(res)
                //私域流量渠道销售额统计
            const chart1 = echarts.init(document.getElementById('main1'));
            var option1;
            option1 = {
                tooltip: {
                    trigger: 'axis',
                    position: ['50%', '50%']// position: ['10px', '10px']
                },
                legend: {
                    data: res.legend,
                    bottom:'1%',
                    textStyle:{
                        color:'rgba(255,255,255,0.8)'
                    }
                },
                grid: { //设置折线图显示边界
                    left: '0%',
                    right: '0%',
                    bottom: '20%',
                    top:'3%',
                    containLabel: true
                },
                xAxis: [
                    {
                        type: 'category',
                        data: res.xAxis
                    }
                ],
                yAxis: [
                    {
                        type: 'value',
                        splitLine:{
                        show:true,
                        lineStyle:{
                            type:'dashed',
                            color:'#fff',
                            opacity: '0.3'
                        }
                    }
                    },
                    {
                        type: 'value',
                        splitLine:{
                        show:true,
                        lineStyle:{
                            type:'dashed',
                            color:'#fff',
                            opacity: '0.3'
                        }
                    }
                    }
                ],
                series: [
                    {
                        name: res.series[0].name,
                        type: 'bar',
                        color:new echarts.graphic.LinearGradient(0, 0, 1, 0, [
                            {
                                offset: 0,
                                color: 'rgba(43, 247, 224, 1)'
                            },
                            {
                                offset: 1,
                                color: 'rgba(75, 135, 254, 1)'
                            }
                        ]), //折线点的颜色,,
                        data: res.series[0].data
                    },
                    {
                        name: res.series[1].name,
                        color:new echarts.graphic.LinearGradient(0, 0, 1, 0, [
                            {
                                offset: 0,
                                color: 'rgba(255, 123, 149, 1)'
                            },
                            {
                                offset: 1,
                                color: 'rgba(250, 63, 100, 1)'
                            }
                        ]), //折线点的颜色,
                        type: 'bar',
                        data: res.series[1].data
                    },
                    {
                        name: res.series[2].name,
                        color:new echarts.graphic.LinearGradient(0, 1, 0, 0, [
                            {
                                offset: 0,
                                color: 'rgba(85, 201, 148, 1)'
                            },
                            {
                                offset: 1,
                                color: 'rgba(85, 255, 148, 1)'
                            }
                        ]), //折线点的颜色,
                        type: 'line',
                        data: res.series[2].data
                    }
                ],
                textStyle:{
                    fontSize: '14',
                    color: '#fff'
                }
            };
            chart1.setOption(option1);
            //私域流量渠道销售额统计
            })
        },

        apply(){    //渠道销售额统计
            let option = {
                type:this.sphere2
            };
            apply(option).then(res => {
                console.log(res)
                //渠道销售额统计
                const chart2 = echarts.init(document.getElementById('main2'));
                var option2;
                option2 = {
                    tooltip: {
                        trigger: 'axis',
                        position: ['50%', '50%']// position: ['10px', '10px']
                    },
                    legend: {
                        data: res.legend,
                        bottom:'1%',
                        textStyle:{
                            color:'rgba(255,255,255,0.8)'
                        }
                    },
                    grid: { //设置折线图显示边界
                        left: '0%',
                        right: '0%',
                        bottom: '20%',
                        top:'3%',
                        containLabel: true
                    },
                    xAxis: [
                        {
                            type: 'category',
                            data: res.xAxis,
                        }
                    ],
                    yAxis: [
                        {
                            type: 'value',
                            splitLine:{
                            show:true,
                            lineStyle:{
                                type:'dashed',
                                color:'#fff',
                                opacity: '0.3'
                            }
                        }
                        },
                        {
                            type: 'value',
                            splitLine:{
                            show:true,
                            lineStyle:{
                                type:'dashed',
                                color:'#fff',
                                opacity: '0.3'
                            }
                        }
                        }
                    ],
                    series: [
                        {
                            name: res.series[0].name,
                            type: 'bar',
                            color:new echarts.graphic.LinearGradient(0, 0, 1, 0, [
                                {
                                    offset: 0,
                                    color: 'rgba(43, 247, 224, 1)'
                                },
                                {
                                    offset: 1,
                                    color: 'rgba(75, 135, 254, 1)'
                                }
                            ]), //折线点的颜色,,
                            data: res.series[0].data
                        },
                        {
                            name: res.series[1].name,
                            color:new echarts.graphic.LinearGradient(0, 0, 1, 0, [
                                {
                                    offset: 0,
                                    color: 'rgba(255, 123, 149, 1)'
                                },
                                {
                                    offset: 1,
                                    color: 'rgba(250, 63, 100, 1)'
                                }
                            ]), //折线点的颜色,
                            type: 'bar',
                            data: res.series[1].data
                        },
                        {
                            name: res.series[2].name,
                            color:new echarts.graphic.LinearGradient(0, 1, 0, 0, [
                                {
                                    offset: 0,
                                    color: 'rgba(85, 201, 148, 1)'
                                },
                                {
                                    offset: 1,
                                    color: 'rgba(85, 255, 148, 1)'
                                }
                            ]), //折线点的颜色,
                            type: 'line',
                            data: res.series[2].data
                        },
                        {
                            name: res.series[3].name,
                            color:new echarts.graphic.LinearGradient(0, 1, 0, 0, [
                                {
                                    offset: 0,
                                    color: '#5065F9'
                                },
                                {
                                    offset: 1,
                                    color: '#5065F9'
                                }
                            ]), //折线点的颜色,
                            type: 'line',
                            data: res.series[3].data
                        }
                    ],
                    textStyle:{
                        fontSize: '14',
                        color: '#fff'
                    }
                    };
                chart2.setOption(option2);
                // 渠道销售额统计
            })
        },

        initChartuser(){   //私域流量渠道用户新增统

            //私域流量渠道用户新增统
            let option = {
                type:this.sphere3
            };
            user(option).then(res => {
                console.log(res)
                //渠道销售额统计
                const chart3 = echarts.init(document.getElementById('main3'));
                var option3;
                option3 = {
                    tooltip: {
                        trigger: 'axis',
                        position: ['50%', '50%']// position: ['10px', '10px']
                    },
                    legend: {
                        data: res.legend,
                        bottom:'1%',
                        textStyle:{
                            color:'rgba(255,255,255,0.8)'
                        }
                    },
                    grid: { //设置折线图显示边界
                        left: '0%',
                        right: '0%',
                        bottom: '20%',
                        top:'3%',
                        containLabel: true
                    },
                    xAxis: [
                        {
                            type: 'category',
                            data: res.xAxis,
                        }
                    ],
                    yAxis: [
                        {
                            type: 'value',
                            splitLine:{
                            show:true,
                            lineStyle:{
                                type:'dashed',
                                color:'#fff',
                                opacity: '0.3'
                            }
                        }
                        },
                        {
                            type: 'value',
                            splitLine:{
                            show:true,
                            lineStyle:{
                                type:'dashed',
                                color:'#fff',
                                opacity: '0.3'
                            }
                        }
                        }
                    ],
                    series: [
                        {
                            name: res.series[0].name,
                            type: 'bar',
                            color:new echarts.graphic.LinearGradient(0, 0, 1, 0, [
                                {
                                    offset: 0,
                                    color: 'rgba(43, 247, 224, 1)'
                                },
                                {
                                    offset: 1,
                                    color: 'rgba(75, 135, 254, 1)'
                                }
                            ]), //折线点的颜色,,
                            data: res.series[0].data
                        },
                        {
                            name: res.series[1].name,
                            color:new echarts.graphic.LinearGradient(0, 0, 1, 0, [
                                {
                                    offset: 0,
                                    color: 'rgba(255, 123, 149, 1)'
                                },
                                {
                                    offset: 1,
                                    color: 'rgba(250, 63, 100, 1)'
                                }
                            ]), //折线点的颜色,
                            type: 'bar',
                            data: res.series[1].data
                        },
                        {
                            name: res.series[2].name,
                            color:new echarts.graphic.LinearGradient(0, 1, 0, 0, [
                                {
                                    offset: 0,
                                    color: 'rgba(85, 201, 148, 1)'
                                },
                                {
                                    offset: 1,
                                    color: 'rgba(85, 255, 148, 1)'
                                }
                            ]), //折线点的颜色,
                            type: 'line',
                            data: res.series[2].data
                        },
                        {
                            name: res.series[3].name,
                            color:new echarts.graphic.LinearGradient(0, 1, 0, 0, [
                                {
                                    offset: 0,
                                    color: '#5065F9'
                                },
                                {
                                    offset: 1,
                                    color: '#5065F9'
                                }
                            ]), //折线点的颜色,
                            type: 'line',
                            data: res.series[3].data
                        }
                    ],
                    textStyle:{
                        fontSize: '14',
                        color: '#fff'
                    }
                    };
                chart3.setOption(option3);
                // 渠道销售额统计
            })
            // 私域流量渠道用户新增统

        },
        sphereClick1(type){//私域流量渠道销售额统计
            this.sphere1 = type;
            this.Channel();
        },
        sphereClick2(type){//渠道销售额统计
            this.sphere2 = type;
            this.apply();
        },
        sphereClick3(type){//私域流量渠道用户新增统
            this.sphere3 = type;
            this.initChartuser();
        },
        timeFn() {
            this.timing = setInterval(() => {
                this.dateDay = formatTime(new Date(), 'HH: mm: ss')
                this.dateYear = formatTime(new Date(), 'yyyy-MM-dd')
                this.dateWeek = this.weekday[new Date().getDay()]
            }, 1000)
        },
        cancelLoading() {
            setTimeout(() => {
                this.loading = false
            }, 500)
        }
    }
  }
  </script>

<style lang="scss">
    ::-webkit-scrollbar{background-color: #000;width: 4px;}
    ::-webkit-scrollbar-track {
        background-color: rgba(255, 255, 255, 0.1);
        width: 2px;
    } 
    ::-webkit-scrollbar-thumb {
        background-color: rgba(255, 255, 255, 0.1);
    } 
    ::-webkit-scrollbar-button {
        background-color: rgba(255, 255, 255, 0.1);
    } 
    ::-webkit-scrollbar-corner {
        background-color: rgba(255, 255, 255, 0.1);
    }
    .massRight_con::-webkit-scrollbar-thumb {
        border-radius: 10px;
        width: 2px;
        box-shadow: inset 0 0 5px rgba(255, 255, 255, 0.6);
        background: rgba(255, 255, 255, 0.4);
    }
    //鼠标移入样式
    .massRight_con::-webkit-scrollbar-thumb:hover {
        background: #333333;
    }
</style>
<style lang="scss" scoped>
@import '../assets/scss/index.scss';
.bg{
    position: relative;
    .feack{
            width: 27px;
            height: 25px;
            position: absolute;
            top: 16px;
            left: 20px;
            cursor: pointer;
        }
    .icon_time{
        position: absolute;
        top: 19px; 
        right: 30px;
        img{
            width: 18rpx;
            height: 18px;
            margin-right: 5px;
            float: left;
        }
        font-size: 18px;
        font-weight: bold;
    }
    .title{
        width: 683px;
        height: 42px;
        font-size: 30px;
        font-weight: 600;
        color: #FFFFFF;
        line-height: 42px;
        background: url("../assets/img/top_bj.png") no-repeat center;
        background-size: 683px 24px;
        margin: 0 auto 0;
        text-align: center;
    }
    .title_bj{
        width: 1920px;
        height: 27px;

    }

    // 左
    .massLeft{
        width: 1311px;
        height: 952px;
        float: left;
        background: url('../assets/img/massLeft_bj.png') no-repeat;
        background-size: 1311px 952px;
        margin: 16px 0 0 25px;
        // 总渠道新增用户
        .newUser{
            width:609px;
            height:261px;
            float: left;
            margin: 20px 0 0 30px;
            .dayGmv_title{
                width: auto;
                height: 28px;
                font-size: 20px;
                font-weight: 500;
                color: #34DBFC;
                line-height: 28px;
                margin: 0px 0 0 59px;
                padding-top: 24px;
            }
            .price{
                width: 374px;
                height: 45px;
                // text-align: center;
                margin: 50px auto 0;
                font-size: 34px;
                div{
                    flex:0 0 auto;
                    text-align: center;
                }
                .num{
                    width: auto;
                    height: 45px;
                    margin-right: 10px;
                    font-size: 32px;
                    font-weight: 500;
                    color: #FFFFFF;
                    line-height: 45px;
                    display: inline-block;
                }
                .day_num{
                    display: inline-block;
                    font-size: 24px;
                    height: 45px;
                    margin-top: 25px;
                    span{
                        color: #FF0000;
                    }
                    img{
                        width: 17px;
                        height: 18px;
                        margin-left: 5px;
                    }
                }
            }

            .newPrice{
                width: 458px;
                border-top: 2px solid rgba(24,122,205,0.88);
                margin: 25px auto 0;
                .newPrice_left{
                    width: 228px;
                    height: 80px;
                    display: inline-block;
                    text-align: center;
                    border-right: 2px solid rgba(24,122,205,0.88);
                    .newPrice_left_title{
                        padding-top: 14px;
                        p{
                            height: 22px;
                            font-size: 16px;
                            font-weight: 400;
                            color: #6633CC;
                            line-height: 22px;
                            span{
                                width: 19px;
                                height: 10px;
                                background: #6633CC;
                                border-radius: 5px;
                                display: inline-block;
                                margin-right: 6px;
                            }
                        }
                    }
                    .unm{
                        height: 28px;
                        font-size: 20px;
                        font-weight: 500;
                        color: #6633CC;
                        line-height: 28px;
                    }
                }
            }
        }
        // 总渠道新增用户

        .sphere{
            width: 609px;
            height: 295px;
            background: url('../assets/img/tongji.png') no-repeat;
            background-size: 609px 295px;
            float: left;
            margin: 25px 0 0 30px;
            .sphere_title{
                width: 496px;
                height: 26px;
                overflow: hidden;
                margin-top: 13px;
                .titletext{
                    width: 198px;
                    height: 26px;
                    font-size: 18px;
                    font-weight: 500;
                    color: #F7A830;
                    line-height: 26px;
                    margin-left: 12px;
                    float: left;
                }
                .sphere_tabqiehuan{
                    width: 151px;
                    height: 26px;
                    background: url("../assets/img/ic_zxjg_tab_n.png") no-repeat center;
                    background-size: 151px 26px;
                    text-align: center;
                    float: right;
                    margin-right: 12px;
                    span{
                        width: 30px;
                        height: 26px;
                        font-size: 14px;
                        font-weight: 500;
                        color: rgba(255,255,255,0.8);
                        line-height: 26px;
                        text-align: center;
                        display: inline-block;
                        cursor:pointer;
                    }
                    .on{
                        color: #34DBFC;
                        border-bottom: 1px solid #34DBFC;
                        padding-bottom: 4px;
                    }
                }
            }
            .sphere_con{
                width: 580px;
                height: 235px;
                float: left;
                margin: 13px 0 0 15px;
                // background: #f60;
                // opacity: .3;
            }
            .sphere_conlist{
                .title_text{
                    width: 585px;
                    height: 33px;
                    background: #004091;
                    margin: 12px 12px 0;
                    display: flex;
                    line-height: 33px;
                    span{
                        flex:0 0 auto;
                        text-align: center;
                        font-size: 15px;
                        font-weight: 400;
                    }
                }


                .massRight_con{
                    width: 592px;
                    height: 195px;
                    overflow-y: auto;
                    margin: 0 13px;
                    .conlist{
                        height: 91px;
                        width: 580px;
                        .channel{
                            height: 91px;
                            overflow: hidden;
                            background: #003070;
                            margin-top: 12px;
                            .channel_left{
                                width: 52px;
                                padding: 0 10px;
                                font-size: 15px;
                                font-weight: 400;
                                color: #FFFFFF;
                                float: left;
                                height: 91px;
                                background: #004091;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                            }
                            .channel_right{
                                float: left;
                                div{
                                    span{
                                        display: inline-block;
                                        text-align: center;
                                        height: 45px;
                                        line-height: 45px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        // 私域流量渠道销售额统计


    }

    // 左

    .massRight{
        width: 525px;
        height: 952px;
        background: url('../assets/img/mass_right_bj.png') no-repeat;
        background-size: 525px 952px;
        float: right;
        margin: 16px 25px 0 0;
        .massRight_title{
            margin: 16px 12px 0;
            width: 400px;
            height: 25px;
            font-size: 18px;
            font-weight: 500;
            color: #FFFFFF;
            line-height: 25px;
        }
        .title_text{
            width: 500px;
            height: 33px;
            background: #004091;
            margin: 12px 12px 0;
            display: flex;
            line-height: 33px;
            span{
                flex:0 0 auto;
                text-align: center;
                font-size: 15px;
                font-weight: 400;
            }
        }
        .massRight_con{
            width: 510px;
            height: 825px;
            // background: #f60;
            margin: 12px 12px 0;
            overflow-y: auto;
            .conlist{
                display: flex;
                width: 500px;
                height: 33px;
                background: #003070;
                margin-bottom: 18px;
                span{
                    flex:0 0 auto;
                    text-align: center;
                    font-size: 15px;
                    font-weight: 400;
                    height: 33px;
                    line-height: 33px;
                    color: rgba(255,255,255,0.7);
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                }
            }
            .conlist:last-child{
                margin-bottom: 0;
            }
        }
    }

    // 右
    
}
</style>
  